'use client'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'

// Inline the styles via ssr rather than loading them on-demand
export default function InitFontAwesomeIcons() {
  config.autoAddCss = false
  return null
}
