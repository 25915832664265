'use client'

import Link from 'next/link'
import { styled, css } from 'styled-components'

import {
  Breakpoints,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

// Common styles
const flexColumn = css`
  display: flex;
  flex-direction: column;
`

const hoverEffect = css`
  transition: all 0.3s ease;
  &:hover {
    background: ${designColors.neutral.lightGray};
  }
`

// Dropdown positioning
const dropdownPosition = css<{ $fullWidth?: boolean; $alignRight?: boolean }>`
  ${({ $fullWidth, $alignRight }) => {
    if ($fullWidth)
      return css`
        left: var(--dropdown-offset);
        right: var(--dropdown-offset);
        width: calc(100% - (var(--dropdown-offset) * 2));
        transform: none;
      `
    if ($alignRight)
      return css`
        right: var(--dropdown-offset);
        left: auto;
        transform: translateX(0);
      `
    return css`
      left: var(--dropdown-offset);
      right: auto;
      transform: none;
    `
  }}
`

export const Dropdown = styled.div<{
  $columns: number
  $fullWidth?: boolean
  $alignRight?: boolean
}>`
  --dropdown-offset: 2.5rem;
  position: absolute;
  ${dropdownPosition}
  display: grid;
  grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
  grid-auto-rows: auto;
  grid-gap: 1rem;
  z-index: 1;
  padding: 1rem;
  ${Shadows.Hover};
  border-radius: 0.3125rem;
  background: ${designColors.neutral.white};
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s linear,
    visibility 0.2s linear;

  @media ${Breakpoints.max.medium} {
    --dropdown-offset: 1.5rem;
  }
`

export const NavLink = styled.div`
  ${Typography.Body2}
  font-weight: 600;
  padding: 0.9375rem 0;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.125rem;
    left: 0;
    bottom: 0;
    background-color: ${designColors.primary.default};
    transition: width 0.3s ease-in-out;
  }

  &:hover {
    font-weight: bold;
    &::after {
      width: 100%;
    }
  }

  @media ${Breakpoints.max.medium} {
    ${Typography.Body3}
    padding: 0.625rem 0;
  }
`

export const DropdownItem = styled(Link)`
  ${flexColumn}
  ${hoverEffect}
  width: 100%;
  padding: 1rem 2.5rem;
  color: ${designColors.neutral.black};
  text-decoration: none;
  border-radius: 0.3125rem;

  @media ${Breakpoints.max.medium} {
    padding: 1rem 2rem;
  }
`

export const DDItemName = styled.div`
  color: ${designColors.neutral.black};
  ${Typography.Title3}
  font-weight: 600;

  @media ${Breakpoints.max.medium} {
    ${Typography.Body4}
    font-weight: 600;
  }
`

export const DDItemDescription = styled.div`
  ${flexColumn}
  ${Typography.Body3}
  color: ${designColors.neutral.darkGray};
  max-width: 12.1875rem;
  margin-top: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${Breakpoints.max.medium} {
    ${Typography.Body4}
  }
`

export const DropdownLinkContainer = styled.div`
  &:hover ${Dropdown} {
    opacity: 1;
    visibility: visible;
  }
`
