'use client'

import { styled, css } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import { Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

// Common styles
const flexColumn = css`
  display: flex;
  flex-direction: column;
`

const linkBase = css`
  text-decoration: none;
  ${Typography.Body3}
  transition: color 0.3s ease;
`
export const RegisterButton = styled(ButtonAsA)`
  ${linkBase}
  transition: all 0.3s ease;
  margin-top: 1.875rem;
`

export const MobileNavLinks = styled.nav`
  ${flexColumn}
  align-items: flex-start;
  padding: 4.0625rem 2.5rem 1.875rem 1.875rem;
`

export const MobileNavSectionTitle = styled.h3`
  ${Typography.Body3}
  padding: 1rem 0 0;
  margin: 0;
  width: 100%;
  color: ${designColors.neutral.darkGray};
  text-transform: uppercase;
  font-weight: 600;
`

export const MobileNavSection = styled.div`
  ${flexColumn}
  width: 100%;
  color: ${designColors.neutral.darkGray};
`

export const MobileNavLink = styled.a`
  ${linkBase}
  padding: 0.3125rem 0;
  color: ${designColors.neutral.pureBlack};

  &:hover {
    color: ${designColors.primary.default};
  }
`
