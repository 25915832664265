'use client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

const flexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`

const absoluteCenter = `
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const IconContainer = styled.div<{
  $top?: string
  $right?: string
  $left?: string
  $bottom?: string
  $size?: string
}>`
  ${flexCenter}
  ${absoluteCenter}
  position: absolute;
  text-align: center;
  max-width: max-content;
  top: ${({ $top }) => $top || '50%'};
  right: ${({ $right }) => $right || 'auto'};
  left: ${({ $left }) => $left || 'auto'};
  bottom: ${({ $bottom }) => $bottom || 'auto'};
  font-size: ${({ $size }) => $size || '1rem'};
  cursor: pointer;
`

export const StyledFAIcon = styled(FontAwesomeIcon)<{ $size?: string }>`
  color: inherit;
  font-size: ${({ $size }) => $size || '1rem'};
`

export const HeaderDiv = styled.div`
  --header-padding: 2.5rem;
  --header-height: 3.125rem;

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-areas: 'navbrand searchwidget nav userActions';
  gap: 1.25rem;
  align-items: center;
  background: ${designColors.neutral.white};
  width: 100%;
  max-width: 90rem;
  height: var(--header-height);
  max-height: var(--header-height);
  margin: 0 auto;
  padding: 0 var(--header-padding);
  position: relative;

  @media ${Breakpoints.max.medium} {
    --header-height: auto;
    grid-template-areas: 'navtrigger navbrand searchwidget mobileUserActions';
  }

  @media ${Breakpoints.max.small} {
    --header-padding: 0.625rem;
    grid-template-columns: auto auto 1fr auto;
    column-gap: 0;
    padding: 1rem var(--header-padding);
  }
`

export const NavBrand = styled.a`
  grid-area: navbrand;
  display: flex;
  align-items: center;
  height: 3.125rem;
  padding: 0;
  margin: 0;
`

export const SearchWidgetContainer = styled.div`
  grid-area: searchwidget;
  ${flexCenter}
  position: relative;
  width: 100%;
  height: 3.125rem;
  margin: 0;
  padding: 0;
  justify-self: stretch;

  @media ${Breakpoints.max.small} {
    justify-content: flex-end;
  }
`

export const StyledInput = styled.input`
  color: ${designColors.neutral.darkGray};
  padding: 0.625rem 2.5rem 0.625rem 0.625rem;
  border: 0.0625rem solid ${designColors.neutral.darkGray};
  border-radius: 0.25rem;
  width: 100%;
  ${Typography.Body3};
  margin: 0;

  @media ${Breakpoints.max.small} {
    display: none;
  }
`

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  max-width: max-content;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  cursor: pointer;

  @media ${Breakpoints.max.small} {
    right: 0;
  }
`

export const SearchIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.75rem;

  @media ${Breakpoints.max.small} {
    font-size: 1.25rem;
  }
`

export const Nav = styled.nav`
  grid-area: nav;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  height: 3.125rem;
  margin: 0;
  padding: 0 1rem;

  @media ${Breakpoints.max.medium} {
    display: none;
  }
`

export const BstockLogoImg = styled(Image)`
  max-width: 10.3125rem;
  height: auto;
`

export const NavLink = styled(Link)`
  display: block;
  text-decoration: none;
  ${Typography.Body2};
  line-height: 1;
  font-weight: 600;
  padding: 0.9375rem 0;
  position: relative;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.125rem;
    left: 0;
    bottom: 0;
    background-color: ${designColors.primary.default};
    transition: width 0.3s ease-in-out;
  }

  &:hover {
    font-weight: bold;

    &:after {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    font-size: 0.875rem;
    padding: 0.625rem 0;
  }
`

export const UserActivitySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const RegisterButton = styled(ButtonAsA)`
  height: 2.375rem;
  ${Typography.Body4};
  color: ${designColors.neutral.white};
  background: ${designColors.primary.brightBlue};
  font-weight: bold;
  border-radius: 0.3125rem;
  padding: 0 0.9375rem;
  transition: all 0.3s;
`

export const MobileNavTrigger = styled.button`
  grid-area: navtrigger;
  display: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 1rem;

  @media ${Breakpoints.max.medium} {
    display: block;
  }
`

export const ActionsContainer = styled.div`
  align-items: center;
  border-radius: 0.25rem;
  background: ${designColors.neutral.lightGray};
  display: flex;
  gap: 0.125rem;
  padding: 0.375rem 0.5rem 0.375rem 0.375rem;
`
