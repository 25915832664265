'use client'

import {
  faFacebookF,
  faXTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { styled } from 'styled-components'

import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

const FooterWrapper = styled.footer`
  ${Typography.Body2}
  color: ${designColors.neutral.black};
  border-top: 0.00625rem solid ${designColors.neutral.mediumGray};
  padding: 3rem 2.5rem 1.5rem;
  max-width: 75rem;
  margin: 0 auto;

  @media ${Breakpoints.min.large} {
    padding: 4rem 1.5rem 2.5rem;
  }
`

const FooterNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;

  @media ${Breakpoints.min.medium} {
    gap: 1.5rem 0;
  }

  @media ${Breakpoints.min.large} {
    flex-wrap: nowrap;
  }
`

const Column = styled.div`
  min-width: fit-content;
  width: 11.5rem;
  flex-basis: 50%;

  @media ${Breakpoints.min.medium} {
    flex-basis: 25%;
  }

  @media ${Breakpoints.min.large} {
    flex-basis: auto;
  }
`

const LogoColumn = styled(Column)`
  flex-grow: 1;
  margin-bottom: 1rem;
  flex-basis: 100%;
  width: auto;

  @media ${Breakpoints.min.medium} {
    flex-basis: 100%;
  }

  @media ${Breakpoints.min.large} {
    flex-basis: auto;
    margin-bottom: 2rem;
  }
`

const ColumnTitle = styled.h3`
  ${Typography.Title3}
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
`

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const LinkItem = styled.li`
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${designColors.primary.brightBlue};
  }
`

const BottomSection = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
`

const SocialIcon = styled(FontAwesomeIcon)`
  width: 2rem;
  font-size: 1rem;
  color: ${designColors.primary.default};
  transition: color 0.3s ease;

  &:hover {
    color: ${designColors.primary.brightBlue};
  }

  @media ${Breakpoints.min.medium} {
    width: 2.5rem;
    font-size: 1.5rem;
  }
`

const Copyright = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoints.max.medium} {
    font-size: 0.875rem;
  }
`

const LogoImage = styled(Image)`
  width: 6.25rem;
  height: 1.5625rem;

  object-fit: contain;
  object-position: left top;

  @media ${Breakpoints.min.medium} {
    width: 9rem;
    height: 2.25rem;
  }
`

const Footer = () => {
  return (
    <FooterWrapper data-gtm-event-position="footer">
      <FooterNav data-gtm-element-type="footer item">
        <LogoColumn>
          <Link href="/">
            <LogoImage
              src="/home-portal/images/bstock_logo.svg"
              alt="B-Stock Solutions"
              width={144}
              height={36}
            />
          </Link>
        </LogoColumn>
        <Column>
          <ColumnTitle>Support</ColumnTitle>
          <LinkList>
            <LinkItem>
              <Link href="/contact-us/">Contact Us</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/terms-of-use/">Terms of Use</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/privacy-policy/">Privacy Policy</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/faq/">FAQs</Link>
            </LinkItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnTitle>Company</ColumnTitle>
          <LinkList>
            <LinkItem>
              <Link href="/about-us/">About Us</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/careers/">Careers</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/press-media/">Press & Media</Link>
            </LinkItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnTitle>For Buyers</ColumnTitle>
          <LinkList>
            <LinkItem>
              <Link href="/buyer-resource-center/">How to Buy</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/blog/category/buyer/">Buyer Blog</Link>
            </LinkItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnTitle>For Sellers</ColumnTitle>
          <LinkList>
            <LinkItem>
              <Link href="/how-to-sell/">How to Sell</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/blog/category/seller/">Seller Blog</Link>
            </LinkItem>
          </LinkList>
        </Column>
      </FooterNav>
      <BottomSection>
        <SocialIcons data-gtm-element-type="footer social">
          <Link
            href="https://www.facebook.com/bstocksolutions"
            aria-label="Facebook"
            data-gtm-element-text="Facebook"
          >
            <SocialIcon icon={faFacebookF} />
          </Link>
          <Link
            href="https://x.com/bstocksol"
            aria-label="X"
            data-gtm-element-text="X"
          >
            <SocialIcon icon={faXTwitter} />
          </Link>
          <Link
            href="https://linkedin.com/company/bstocksolutions"
            aria-label="LinkedIn"
            data-gtm-element-text="LinkedIn"
          >
            <SocialIcon icon={faLinkedinIn} />
          </Link>
        </SocialIcons>
        <Copyright>&copy; 2024 B-Stock Solutions, LLC</Copyright>
      </BottomSection>
    </FooterWrapper>
  )
}

export default Footer
