'use client'

import { useState } from 'react'

import { faBars } from '@fortawesome/pro-regular-svg-icons'

import { SlideOut } from '@b-stock/bstock-react'

import DropdownNavigation from './dropdownNavigation/DropdownNavigation'
import MobileNavigation from './mobileNavigation/MobileNavigation'
import SearchWidget from './SearchWidget'
import {
  HeaderDiv,
  NavBrand,
  BstockLogoImg,
  Nav,
  StyledFAIcon,
  MobileNavTrigger,
} from './ui'
import MobileUserActions from './userActions/MobileUserActions'
import UserActions from './userActions/UserActions'
export default function HeaderPrimaryNavigation() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <HeaderDiv
      id="headerDiv"
      data-gtm-event-position="header"
      data-gtm-element-type="header item"
    >
      <MobileNavTrigger id="navTrigger" onClick={() => setIsOpen(!isOpen)}>
        <StyledFAIcon id="openIcon" icon={faBars} $size="1.375rem" />
      </MobileNavTrigger>

      <SlideOut width="80%" side="left" isOpen={isOpen} setIsOpen={setIsOpen}>
        <MobileNavigation />
      </SlideOut>

      <NavBrand
        id="navBrand"
        href="/"
        data-gtm-element-type="header logo"
        data-gtm-element-text="Logo"
      >
        <BstockLogoImg
          id="bstockLogoImg"
          src="/home-portal/images/bstock_logo.svg"
          alt="B-Stock Solutions, LLC."
          width={200}
          height={50}
        />
      </NavBrand>

      <SearchWidget />

      <Nav id="nav">
        <DropdownNavigation />
      </Nav>
      <UserActions />
      <MobileUserActions />
    </HeaderDiv>
  )
}
