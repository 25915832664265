'use client'
import { useEffect, useState, useRef } from 'react'

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import {
  categoriesData,
  sellersData,
  conditionsData,
  regionsData,
} from './auctionNavData'
import {
  CategoriesMobileSubMenu,
  SellersMobileSubMenu,
  ConditionsMobileSubMenu,
  RegionsMobileSubMenu,
} from './auctionNavMobileSubMenu'
import SubMenu from './auctionNavSubMenu/SubMenu'
import {
  ScrollableNavbarContainer,
  AuctionNavDivider,
  Navbar,
  NavbarLi,
  NavbarLink,
  NavbarUl,
  NavSubMenuIcon,
  Overlay,
  BodyWithOpenMobileMenu,
} from './ui'
import { useNavbarScroll } from './useNavbarScroll'

// Our standard breakpoints don't meet the needs of the WP Home
// TODO: Research a better way or get improved Home Page designs to match our design system

const DESKTOP_BREAKPOINT = 1201

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.innerWidth > DESKTOP_BREAKPOINT)
    }

    checkIsDesktop()
    window.addEventListener('resize', checkIsDesktop)

    return () => window.removeEventListener('resize', checkIsDesktop)
  }, [])

  return isDesktop
}

export default function HeaderAuctionNavigation() {
  const { isScrollable, navbarRef } = useNavbarScroll()
  const isDesktop = useIsDesktop()
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null)

  const handleToggle = (menu: string) => {
    setActiveSubMenu((prevMenu) => (prevMenu === menu ? null : menu))
  }

  const activeSubMenuRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If there's an active submenu and the click is outside of it, close it
      if (
        activeSubMenuRef.current &&
        !activeSubMenuRef.current.contains(event.target as Node)
      ) {
        setActiveSubMenu(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { trackButtonClicked } = useAnalytics()

  const handleTrackNavigation = (section: string) => {
    return (event: React.MouseEvent) => {
      const target = event.target as HTMLElement
      const anchor = target.closest('a')

      if (anchor) {
        const href = anchor.getAttribute('href')
        let anchorText = anchor.innerText.trim()

        if (!anchorText) {
          const img = anchor.querySelector('img')
          if (img) {
            anchorText = img.getAttribute('alt') || ''
          }
        }

        // Segment analytics event and metadata transcribed from old wordpress code
        // TODO: Update entity_type to based on the role of the user once we
        // implement user authentication.
        trackButtonClicked(
          'homepage', // screen_name
          'header_click', // button_name
          'home_portal', // source
          'buyer', // entity_type
          {
            header_section: section,
            header_subsection: anchorText
              .replace(/[^\w\s]/gi, ' ')
              .toLowerCase()
              .replace(/\s+/g, '_'),
            url: document.URL,
            referrer: document.referrer,
            cta_url: href,
          }
        )
      }
    }
  }

  useEffect(() => {
    if (activeSubMenu && !isDesktop) {
      document.body.classList.add('mobile-menu-open')
    } else {
      document.body.classList.remove('mobile-menu-open')
    }

    return () => {
      document.body.classList.remove('mobile-menu-open')
    }
  }, [activeSubMenu, isDesktop])

  return (
    <>
      <BodyWithOpenMobileMenu />
      <Navbar>
        <ScrollableNavbarContainer $isScrollable={isScrollable} ref={navbarRef}>
          <NavbarUl onClick={handleTrackNavigation('all_auctions')}>
            <NavbarLi>
              <NavbarLink href="/all-auctions/">All Auctions</NavbarLink>
            </NavbarLi>
          </NavbarUl>
          <AuctionNavDivider $height="75%" />
          <NavbarUl onClick={handleTrackNavigation('categories')}>
            <NavbarLi>
              <NavbarLink href="/auctions/appliances/">Appliances</NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink href="/auctions/cell-phones/">Cell Phones</NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink
                href="#"
                onClick={() => handleToggle('categories')}
                data-testid="navbar-all-categories-link"
              >
                All Categories <NavSubMenuIcon icon={faChevronDown} />
              </NavbarLink>
              {isDesktop && (
                <SubMenu
                  layout="single-column-wrapped"
                  data={categoriesData}
                  $fullWidth
                />
              )}
            </NavbarLi>
          </NavbarUl>
          <AuctionNavDivider $height="75%" />
          <NavbarUl onClick={handleTrackNavigation('sellers')}>
            <NavbarLi>
              <NavbarLink href="/amazon/">Amazon</NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink href="/samsung/">Samsung</NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink
                href="#"
                onClick={() => handleToggle('sellers')}
                data-testid="navbar-all-sellers-link"
              >
                All Sellers <NavSubMenuIcon icon={faChevronDown} />
              </NavbarLink>
              {isDesktop && (
                <SubMenu
                  layout="two-column-wrap"
                  data={sellersData}
                  $fullWidth
                />
              )}
            </NavbarLi>
          </NavbarUl>
          <AuctionNavDivider $height="75%" />
          <NavbarUl onClick={handleTrackNavigation('conditions')}>
            <NavbarLi>
              <NavbarLink href="/auctions/like-new-condition-goods/">
                Like New
              </NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink href="/auctions/mixed-condition/">Mixed</NavbarLink>
            </NavbarLi>
            <NavbarLi $hasAlignRightSubMenu>
              <NavbarLink
                href="#"
                onClick={() => handleToggle('conditions')}
                data-testid="navbar-all-conditions-link"
              >
                All Conditions <NavSubMenuIcon icon={faChevronDown} />
              </NavbarLink>
              {isDesktop && (
                <SubMenu
                  layout="single-column"
                  data={conditionsData}
                  $alignRight
                />
              )}
            </NavbarLi>
          </NavbarUl>
          <AuctionNavDivider $height="75%" />
          <NavbarUl onClick={handleTrackNavigation('regions')}>
            <NavbarLi>
              <NavbarLink href="/auctions/northeast-auctions/">
                Northeast
              </NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink href="/auctions/midwest-auctions/">
                Midwest
              </NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink href="/auctions/south-auctions/">South</NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink href="/auctions/west-auctions/">West</NavbarLink>
            </NavbarLi>
            <NavbarLi>
              <NavbarLink
                href="#"
                onClick={() => handleToggle('regions')}
                data-testid="navbar-all-regions-link"
              >
                All Regions <NavSubMenuIcon icon={faChevronDown} />
              </NavbarLink>
              {isDesktop && (
                <SubMenu layout="three-column" data={regionsData} $fullWidth />
              )}
            </NavbarLi>
          </NavbarUl>
        </ScrollableNavbarContainer>
      </Navbar>

      {/* Conditionally render MobileSubMenus */}
      {!isDesktop && activeSubMenu && (
        <Overlay onClick={() => setActiveSubMenu(null)} />
      )}
      {!isDesktop && activeSubMenu === 'categories' && (
        <CategoriesMobileSubMenu
          onClose={() => setActiveSubMenu(null)}
          onClick={handleTrackNavigation('categories')}
        />
      )}
      {!isDesktop && activeSubMenu === 'sellers' && (
        <SellersMobileSubMenu
          onClose={() => setActiveSubMenu(null)}
          onClick={handleTrackNavigation('sellers')}
        />
      )}
      {!isDesktop && activeSubMenu === 'conditions' && (
        <ConditionsMobileSubMenu
          onClose={() => setActiveSubMenu(null)}
          onClick={handleTrackNavigation('conditions')}
        />
      )}
      {!isDesktop && activeSubMenu === 'regions' && (
        <RegionsMobileSubMenu
          onClose={() => setActiveSubMenu(null)}
          onClick={handleTrackNavigation('regions')}
        />
      )}
    </>
  )
}
