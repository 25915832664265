interface RegionItem {
  name: string
  href: string
}

interface RegionHeader {
  title: string
}

interface RegionFooter {
  text: string
  href: string
}

interface Region {
  header: RegionHeader
  items: RegionItem[]
  footer: RegionFooter
}

export interface AllRegionsDataProps {
  unitedStates: Region
  canada: Region
  europe: Region
}

const allRegionsData: AllRegionsDataProps = {
  unitedStates: {
    header: { title: 'United States' },
    items: [
      {
        name: 'Northeast',
        href: '/auctions/northeast-auctions/',
      },
      {
        name: 'Midwest',
        href: '/auctions/midwest-auctions/',
      },
      {
        name: 'South',
        href: '/auctions/south-auctions/',
      },
      {
        name: 'West',
        href: '/auctions/west-auctions/',
      },
    ],
    footer: {
      text: 'Shop All US',
      href: '/auctions/united-states/',
    },
  },
  canada: {
    header: { title: 'Canada' },
    items: [
      {
        name: 'Alberta',
        href: '/auctions/canada/alberta/',
      },
      {
        name: 'British Columbia',
        href: '/auctions/canada/british-columbia/',
      },
      {
        name: 'Quebec',
        href: '/auctions/canada/quebec/',
      },
      {
        name: 'Ontario',
        href: '/auctions/canada/ontario-canada/',
      },
    ],
    footer: {
      text: 'Shop All CA',
      href: '/auctions/canada/',
    },
  },
  europe: {
    header: { title: 'Europe' },
    items: [
      {
        name: 'France',
        href: '/auctions/france/',
      },
      {
        name: 'Germany',
        href: '/auctions/germany/',
      },
      {
        name: 'Poland',
        href: '/auctions/poland-liquidation-auctions/',
      },
      {
        name: 'Spain',
        href: '/auctions/spain/',
      },
      {
        name: 'United Kingdom',
        href: '/auctions/united-kingdom/',
      },
    ],
    footer: {
      text: 'Shop All Europe',
      href: '/auctions/europe/',
    },
  },
}

export default allRegionsData
