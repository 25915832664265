'use client'

import type { LogsEvent } from '@datadog/browser-logs'

interface ImageErrorEvent extends Omit<LogsEvent, 'message'> {
  error?: {
    origin?: string
    message?: string
    stack?: string
  }
  status?: string
  message: string
}

export const datadogBeforeSend = (event: ImageErrorEvent): boolean => {
  // Check if this is a console error with our specific message format
  if (
    event.origin === 'console' &&
    event.message.includes('upstream image response failed for')
  ) {
    // Extract the image URL from the message
    const messageArray = event.message.split(',')
    const imageUrl = messageArray[messageArray.length - 1]?.trim() || 'unknown'

    // Modify the event
    Object.assign(event, {
      status: 'info' as const,
      message: 'Image load failed',
      error: {
        ...event.error,
        stack: JSON.stringify({
          imageUrl,
          pageUrl:
            typeof window !== 'undefined' ? window.location.href : 'unknown',
          pathname:
            typeof window !== 'undefined'
              ? window.location.pathname
              : 'unknown',
        }),
      },
    })
  }
  return true
}
