'use client'

import type { ReactNode } from 'react'
import React from 'react'

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import type { BaseLogger } from 'pino'

import { getLogger } from '@b-stock/bstock-next'
import { EmptyState } from '@b-stock/bstock-react'

import Footer from '@components/Footer'
import Header from '@components/Header'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const logger = getLogger() as BaseLogger
    logger.error(
      'Error caught by ErrorBoundary in home-portal:',
      error,
      errorInfo
    )
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header />
          <EmptyState
            icon={faExclamationTriangle}
            title="500"
            description="Something went wrong. Please try again later."
          />
          <Footer />
        </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
