interface ConditionItem {
  name: string
  href: string
}

interface ConditionHeader {
  title: string
}

export interface AllConditionsDataProps {
  header: ConditionHeader
  items: ConditionItem[]
}

const allConditionsData: AllConditionsDataProps = {
  header: {
    title: 'All Conditions',
  },
  items: [
    {
      name: 'Brand New',
      href: '/auctions/new-condition-inventory/',
    },
    {
      name: 'Like New',
      href: '/auctions/like-new-condition-goods/',
    },
    {
      name: 'Mixed Condition',
      href: '/auctions/mixed-condition/',
    },
    {
      name: 'Salvage',
      href: '/auctions/salvage-liquidation-auctions/',
    },
    {
      name: 'Scratch & Dent Appliances',
      href: '/auctions/appliance-scratch-dent-auctions/',
    },
    {
      name: 'Used - Fair',
      href: '/auctions/used-fair/',
    },
    {
      name: 'Used - Good',
      href: '/auctions/used-good/',
    },
  ],
}

export default allConditionsData
