interface SellersItem {
  name: string
  href: string
  ariaLabel: string
}

interface SellersHeader {
  title: string
}

export interface SellersDataProps {
  topSellersHeader: SellersHeader
  topSellers: SellersItem[]
  allSellersHeader: SellersHeader
  allSellers: SellersItem[]
}

const sellersData: SellersDataProps = {
  topSellersHeader: {
    title: 'Top Sellers',
  },
  topSellers: [
    {
      name: 'Amazon',
      href: '/amazon/',
      ariaLabel: 'Amazon Liquidation Auctions',
    },
    {
      name: 'Best Buy',
      href: '/bestbuy/',
      ariaLabel: 'Best Buy Liquidation Auctions',
    },
    {
      name: 'Costco Wholesale',
      href: '/costco/',
      ariaLabel: 'Costco Wholesale Liquidation Auctions',
    },
    {
      name: 'Mobile Carrier',
      href: '/mobilecarrier/',
      ariaLabel: 'Mobile Carrier Liquidations',
    },
    {
      name: 'Samsung Mobile',
      href: '/samsung/',
      ariaLabel: 'Samsung Mobile Auctions',
    },
    {
      name: 'Superior Wireless',
      href: '/superior/',
      ariaLabel: 'Superior Wireless Auctions',
    },
    {
      name: 'Target',
      href: '/target/',
      ariaLabel: 'Target Auctions Liquidation',
    },
    {
      name: 'The Home Depot',
      href: '/homedepot/',
      ariaLabel: 'The Home Depot Liquidation Auctions',
    },
    {
      name: 'Walmart',
      href: 'https://liquidations.walmart.com/',
      ariaLabel: 'Walmart Liquidation Auctions',
    },
    {
      name: 'Wireless Network',
      href: '/wirelessnetwork/',
      ariaLabel: 'Wireless Network Marketplace',
    },
  ],
  allSellersHeader: {
    title: 'All Sellers',
  },
  allSellers: [
    {
      name: 'Ace Hardware',
      href: '/acehardware/',
      ariaLabel: 'Ace Hardware Liquidation Auctions',
    },
    {
      name: 'Advance Auto',
      href: '/advanceauto/',
      ariaLabel: 'Advance Auto Parts',
    },
    {
      name: 'a.k.a Brands',
      href: '/akabrands/',
      ariaLabel: 'a.k.a. Brands Liquidation Auctions',
    },
    {
      name: 'Almo',
      href: '/almo/',
      ariaLabel: 'Almo Liquidation Auctions',
    },
    {
      name: 'Alphabroder',
      href: '/buy/sellers/details/alphabroder',
      ariaLabel: 'Alphabroder Liquidation Auctions',
    },
    {
      name: 'Amazon',
      href: '/amazon/',
      ariaLabel: 'Amazon Liquidation Auctions',
    },
    {
      name: 'Amazon Europe',
      href: '/amazoneu/',
      ariaLabel: 'Amazon Liquidation Auctions EU',
    },
    {
      name: 'Ashley Homestore',
      href: '/ashley/',
      ariaLabel: 'Ashley Homestore Liquidation Auctions',
    },
    {
      name: 'B-Stock Supply',
      href: '/supply/',
      ariaLabel: 'B-Stock Supply',
    },
    {
      name: 'B-Stock Supply Europe',
      href: '/supply-europe/',
      ariaLabel: 'B-Stock Supply EU',
    },
    {
      name: 'Bayer',
      href: '/bayer/',
      ariaLabel: 'Bayer Liquidation Auctions',
    },
    {
      name: 'Best Buy',
      href: '/bestbuy/',
      ariaLabel: 'Best Buy Liquidation Auctions',
    },
    {
      name: 'Beyond Brands',
      href: '/buy/sellers/details/beyond',
      ariaLabel: 'Beyond Brands',
    },
    {
      name: 'Big 5 Sporting Goods',
      href: '/big5sportinggoods/',
      ariaLabel: 'Big 5 Sporting Goods',
    },
    {
      name: "BJ's",
      href: '/buy/sellers/details/bjs',
      ariaLabel: "BJ's Liquidation Auctions",
    },
    {
      name: 'Certideal',
      href: '/certideal/',
      ariaLabel: 'Certideal Liquidation Auctions',
    },
    {
      name: 'Contemporary Fashion Auctions',
      href: '/contemporaryfashion/',
      ariaLabel: 'Contemporary Fashion Auctions',
    },
    {
      name: 'Costco Appliances',
      href: '/buy/sellers/details/costcoappliances',
      ariaLabel: 'Costco Appliances and Home Liquidation Auctions',
    },
    {
      name: 'Costco Canada',
      href: '/costcocanada/',
      ariaLabel: 'Costco Liquidation Auctions Canada',
    },
    {
      name: 'Costco Wholesale',
      href: '/costco/',
      ariaLabel: 'Costco Wholesale Liquidation Auctions',
    },
    {
      name: 'Crescent Electric Supply Company',
      href: '/buy/sellers/details/crescentelectricsupply',
      ariaLabel: 'Crescent Electric Supply Company',
    },
    {
      name: "Dick's Sporting Goods",
      href: '/dickssportinggoods/',
      ariaLabel: "Dick's Sporting Goods Liquidations",
    },
    {
      name: 'Disney Europe',
      href: '/disneyuk/',
      ariaLabel: 'Disney Liquidation Auctions',
    },
    {
      name: 'Dorel Home',
      href: '/buy/sellers/details/dorel-home',
      ariaLabel: 'Dorel Home',
    },
    {
      name: 'EcoATM',
      href: '/ecoatm/',
      ariaLabel: 'EcoATM Auctions',
    },
    {
      name: 'Ecolab',
      href: '/ecolab/',
      ariaLabel: 'Ecolab Liquidation Auctions',
    },
    {
      name: 'Essendant',
      href: '/essendant/',
      ariaLabel: 'Essendant Liquidation Auctions',
    },
    {
      name: 'GameStop',
      href: '/gamestop/',
      ariaLabel: 'GameStop Wholesale',
    },
    {
      name: 'GE Appliances Canada',
      href: '/geappliancescanada/',
      ariaLabel: 'GE Appliances Canada Liquidation Auctions',
    },
    {
      name: 'GE Appliances',
      href: '/geappliances/',
      ariaLabel: 'GE Appliances Liquidation Auctions',
    },
    {
      name: 'Grainger',
      href: '/grainger/',
      ariaLabel: 'Grainger Liquidation Auctions',
    },
    {
      name: 'Hanes',
      href: '/hanes/',
      ariaLabel: 'Hanes Brands Closeouts',
    },
    {
      name: 'Herman Miller',
      href: '/hermanmiller/',
      ariaLabel: 'Herman Miller Liquidation Auctions',
    },
    {
      name: 'HSN',
      href: '/hsn/',
      ariaLabel: 'HSN Liquidation',
    },
    {
      name: 'HBC',
      href: '/hbc/',
      ariaLabel: "Hudson's Bay Liquidation Auctions",
    },
    {
      name: 'JCPenney',
      href: '/jcpenney/',
      ariaLabel: 'JCPenney Liquidation Auctions',
    },
    {
      name: 'Journeys',
      href: '/journeysgroup/',
      ariaLabel: 'Journeys Liquidations',
    },
    {
      name: 'Kohler',
      href: '/kohler/',
      ariaLabel: 'Kohler Auctions',
    },
    {
      name: "Lands' End",
      href: '/landsend/',
      ariaLabel: "Lands' End Liquidation Auctions",
    },
    {
      name: 'Last Chance Auctions',
      href: '/lastchanceauctions',
      ariaLabel: 'Last Chance Auctions',
    },
    {
      name: "Lowe's",
      href: '/lowes/',
      ariaLabel: "Lowe's Liquidation",
    },
    {
      name: 'Major Sporting Event',
      href: '/majorsportingevent/',
      ariaLabel: 'Major Sporting Event',
    },
    {
      name: 'Meijer',
      href: '/meijer/',
      ariaLabel: 'Meijer Liquidation Auctions',
    },
    {
      name: 'Midwest Catalog Brands',
      href: '/midwestcatalogbrands/',
      ariaLabel: 'Midwest Catalog Brands Liquidation Auctions',
    },
    {
      name: 'Mobile Carrier',
      href: '/mobilecarrier/',
      ariaLabel: 'Mobile Carrier Liquidations',
    },
    {
      name: 'Moove',
      href: '/buy/sellers/details/moove',
      ariaLabel: 'Moove',
    },
    {
      name: 'NFM',
      href: '/nfm/',
      ariaLabel: 'NFM Liquidation Auctions',
    },
    {
      name: 'Petco',
      href: '/buy/sellers/details/petco',
      ariaLabel: 'Petco Liquidation Auctions',
    },
    {
      name: 'Prestige',
      href: '/prestigebrands/',
      ariaLabel: 'Prestige Consumer Healthcare Liquidation Auctions',
    },
    {
      name: 'QVC',
      href: '/qvc/',
      ariaLabel: 'QVC Liquidation Auctions',
    },
    {
      name: 'Rent the Runway',
      href: '/buy/sellers/details/renttherunway',
      ariaLabel: 'Rent the Runway Liquidation Auctions',
    },
    {
      name: 'Revlon',
      href: '/revlonbrands/',
      ariaLabel: 'Revlon Brands Auctions',
    },
    {
      name: "Sam's Club",
      href: '/samsclub/',
      ariaLabel: "Sam's Club Liquidations",
    },
    {
      name: 'Samsung Mobile',
      href: '/samsung/',
      ariaLabel: 'Samsung Mobile Auctions',
    },
    {
      name: 'Signature Hardware',
      href: '/signaturehardware/',
      ariaLabel: 'Signature Hardware Liquidation Auctions',
    },
    {
      name: 'Spectrum Brands',
      href: '/buy/sellers/details/spectrum-brands',
      ariaLabel: 'Spectrum Brands',
    },
    {
      name: 'Squaretrade',
      href: '/squaretrade/',
      ariaLabel: 'SquareTrade Auctions',
    },
    {
      name: 'Superior Wireless',
      href: '/superior/',
      ariaLabel: 'Superior Wireless Auctions',
    },
    {
      name: 'Target',
      href: '/target/',
      ariaLabel: 'Target Auctions Liquidation',
    },
    {
      name: 'The Home Depot',
      href: '/homedepot/',
      ariaLabel: 'The Home Depot Liquidation Auctions',
    },
    {
      name: 'Trillion',
      href: '/trillion/',
      ariaLabel: 'Trillion Wholesale Auctions',
    },
    {
      name: 'UNFI',
      href: '/unfi/',
      ariaLabel: 'UNFI Liquidation Auctions',
    },
    {
      name: 'Unilever',
      href: '/unilever/',
      ariaLabel: 'Unilever Liquidation Auctions',
    },
    {
      name: 'Universal Athletic',
      href: '/universalathletic/',
      ariaLabel: 'Universal Athletic Liquidation Auctions',
    },
    {
      name: 'Veyer',
      href: '/veyer/',
      ariaLabel: 'Veyer Liquidation Auctions',
    },
    {
      name: 'Walgreens',
      href: '/walgreens/',
      ariaLabel: 'Walgreens Liquidation Auctions',
    },
    {
      name: 'Walker Edison',
      href: '/walkeredison/',
      ariaLabel: 'Walker Edison Liquidation Auctions',
    },
    {
      name: 'Walmart',
      href: 'https://liquidations.walmart.com/',
      ariaLabel: 'Walmart Liquidation Auctions',
    },
    {
      name: 'Wayfair Europe',
      href: '/wayfaireu/',
      ariaLabel: 'Wayfair EU Liquidation Auctions',
    },
    {
      name: 'Wayfair',
      href: '/wayfair/',
      ariaLabel: 'Wayfair Liquidation Auctions',
    },
    {
      name: 'Whirlpool Second Channel',
      href: '/whirlpool/',
      ariaLabel: 'Whirlpool Second Channel Marketplace',
    },
    {
      name: 'Wireless Network',
      href: '/wirelessnetwork/',
      ariaLabel: 'Wireless Network Marketplace',
    },
    {
      name: 'Woot',
      href: '/woot/',
      ariaLabel: 'Woot Liquidation Auctions',
    },
    {
      name: 'World Market Auctions',
      href: '/buy/sellers/details/world-market-auctions',
      ariaLabel: 'World Market Auctions',
    },
    {
      name: 'Winston Water Cooler',
      href: '/buy/sellers/details/winston-water-cooler',
      ariaLabel: 'WWC Family of Companies',
    },
  ],
}

export default sellersData
